<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Surat Persetujuan Validasi</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan Fisika</h4>
            </div>
            <hr style="height: 1px; background: black" />

            <ul class="undot">
              <li>Nama Mahasiswa : {{ detail.student_name }}</li>
              <li>NIM : {{ detail.registration_number }}</li>
              <li>Semester ke- : {{ detail.semester_name }}</li>
              <li>Tahun ke- : {{ detail.year_id }}</li>
              <li>Program Studi : {{ detail.study_program_name }}</li>
              <li>Fakultas : {{ detail.faculty_name }}</li>
              <li>Alamat : {{ detail.address }}</li>
              <li>No. Telp/Wa : {{ detail.phone }}</li>
              <li>Alamat Email : {{ detail.email }}</li>
              <!--  -->
              <li>Dosen Pembimbing 1 : {{ detail.teacher_mentor1 }}</li>
              <li>Dosen Pembimbing 2 : {{ detail.teacher_mentor2 }}</li>
              <li>Judul Penelitian : {{ detail.title }}</li>
              <li>Nama Alat / Media Yang Akan Divalidasi : {{ detail.validated_tool_name }}</li>
              <!--  -->
              <li>Keterangan : {{ detail.description }}</li>
            </ul>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div class="d-flex justify-content-start align-items-center image-container">
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <b-button variant="danger" v-b-tooltip.hover title="Cetak PDF" @click="downloadPDF">
          <i class="fas fa-file-pdf px-0"></i>
        </b-button>
      </div>
    </div>
    <b-button @click="$router.push('/letters/validation-approval-form/list')" variant="secondary" class="mt-3">Tutup</b-button>

    <!-- Layout PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="500"
      filename="Surat Persetujuan Validasi"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="950px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <DetailPdf :detail="detail" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import DetailPdf from "@/view/pages/letters/validation-approval-form/DetailPdf.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    DetailPdf,
    VueHtml2pdf,
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    async get() {
      this.detail = await module.get("api/validation-approval-forms/" + this.$route.params.id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/letters/validation-approval-form/list");
      }
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surat Persetujuan Validasi", route: "/validation-approval-form" }, { title: "Detail" }]);

    this.get();
  },
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
